import React from "react";
import { graphql } from "gatsby";

import { ImageDataLike } from "gatsby-plugin-image";

import Layout from "@components/Layout";
import { PostCard, Tab } from "@components/UI";

import * as styles from "@styles/components/Categeory/index.module.scss";
import Seo from "@components/Seo";

type Props = {
  data: {
    allMdx: {
      edges: {
        node: {
          id: string;
          frontmatter: {
            category: string;
            title: string;
            posterImage: ImageDataLike;
            date: string;
          };
          excerpt: string;
          slug: string;
        };
      }[];
    };
  };
};

const CategoryTemplate: React.FC<Props> = ({ data }) => {
  const category =
    data.allMdx.edges.length > 0
      ? data.allMdx.edges[0].node.frontmatter.category
      : "";

  return (
    <Layout>
      <div className={styles.container}>
        <Seo title={`Explore Category ${category}`} />

        {data.allMdx.edges.length > 0 ? (
          <>
            <Tab title={category} />

            <section>
              {data.allMdx.edges.map((file, index) => {
                const {
                  slug,
                  frontmatter: { title, posterImage, category, date },
                  excerpt,
                } = file.node;

                return (
                  <PostCard
                    key={index}
                    poster={posterImage}
                    title={title}
                    date={date}
                    url={`/blog/${slug}`}
                    desc={excerpt}
                    category={category}
                  />
                );
              })}
            </section>
          </>
        ) : (
          <h2 className={styles.error}>Sorry category has not posts</h2>
        )}
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ($slug: String) {
    allMdx(
      filter: { frontmatter: { category: { eq: $slug } } }
      sort: { fields: frontmatter___date }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            category
            posterImage {
              childImageSharp {
                gatsbyImageData
              }
            }
            date(formatString: "MMMM D, YYYY")
          }
          excerpt
          slug
        }
      }
    }
  }
`;

export default CategoryTemplate;
