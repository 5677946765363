import React from "react";
import { Link } from "gatsby";

import {
  GatsbyImage,
  getImage,
  IGatsbyImageData,
  ImageDataLike,
} from "gatsby-plugin-image";

import * as styles from "@styles/components/UI/post-card.module.scss";

type Props = {
  poster: ImageDataLike;
  title: string;
  desc?: string;
  url: string;
  date: string;
  className?: string;
  category?: string;
};

const PostCard: React.FC<Props> = ({
  poster,
  title,
  desc,
  url,
  date,
  className = "",
  category,
}) => {
  return (
    <article className={`${styles.card} ${className}`}>
      <div className={styles.poster}>
        <Link to={url} target="_self">
          <GatsbyImage
            image={getImage(poster) as IGatsbyImageData}
            alt={title}
            imgStyle={{ objectFit: "contain" }}
          />
        </Link>
      </div>

      <div className={styles.details}>
        {category && (
          <Link to={`/category/${category}`} target="_self">
            <span className={styles.category}>{category}</span>
          </Link>
        )}

        <Link to={url} target="_self">
          <h2 className={styles.title}>{title}</h2>
        </Link>

        {desc && <p className={styles.desc}>{desc}</p>}

        <time className={styles.info} dateTime={date}>
          {date}
        </time>
      </div>
    </article>
  );
};

export default PostCard;
