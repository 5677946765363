import React from "react";

import * as styles from "@styles/components/UI/tab.module.scss";

type Props = {
  title: string;
};

const Tab: React.FC<Props> = ({ title }) => {
  return (
    <div className={styles.container}>
      <h2>{title}</h2>
    </div>
  );
};

export default Tab;
